import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import FoundSuccessMobile from '@components/Structural/Founds/FoundSuccessMobile';
import { useAuth } from '@hooks';
const Founds = () => {
  useAuth({ redirectIfNotLogged: true });

  const isMobile = useMediaQuery('(max-width: 1250px)');

  return <FoundSuccessMobile />;
};
export default Founds;
