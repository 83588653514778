import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { ButtonPrimary } from '@components';
import { navigate } from 'gatsby';
import { Route } from '@interfaces';
import Successful from '../Successful/Successful';

const FoundSuccessMobile = () => {
  const classes = useStyles();
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // Los meses se indexan desde 0, por lo que se suma 1
  const day = currentDate.getDate();
  const twoDaysLater = new Date(currentDate);
  const dateTwo = new Date(twoDaysLater.setDate(currentDate.getDate() + 2));
  const yeardateTwo = dateTwo.getFullYear();
  const monthdateTwo = dateTwo.getMonth() + 1; // Los meses se indexan desde 0, se le suma 1
  const daydateTwo = dateTwo.getDate();
  return (
    <div className={`${classes.container}`}>
      <Successful
        title="Tu retiro se ha creado con éxito"
        depositValue={9}
        firstFundSelection=""
      >
        <section className={classes.containerChildren}>
          <div className={classes.dataRetire}>
            <div className={classes.step}>
              <img src="https://storage.googleapis.com/rokinapp-images/investments/CreateNew/check_icon%20(6).svg" />
              <div>
                <span className="title">Retiro creado</span>{' '}
                <span className="subtitle">
                  {day}/{month}/{year}
                </span>
              </div>
            </div>
            <div className={classes.line} />
            <div className={classes.step}>
              <img src="https://storage.googleapis.com/rokinapp-images/investments/CreateNew/inProgress_icon.svg" />
              <div>
                <span className="title">Retiro completado</span>
                <span className="subtitle">
                  Fecha estimada: {daydateTwo}/{monthdateTwo}/{yeardateTwo}{' '}
                </span>
              </div>
            </div>
          </div>
          <div className={classes.textBottom}>
            Verás el depósito en tu cuenta en aproximadamente
            <strong> 2 días hábiles</strong>
          </div>
          <div className={classes.button}>
            <ButtonPrimary
              text="Volver a Mis Inversiones"
              onClick={() => {
                navigate(Route.investments);
              }}
            />
          </div>
        </section>
      </Successful>
    </div>
  );
};
export default FoundSuccessMobile;
const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    fontFamily: 'Montserrat',
    '& .radio': {
      color: theme.palette.primary.main,
    },
    '& >span': {
      color: theme.palette.primary.main,
    },
  },
  dataRetire: {
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    padding: '3rem 0rem 0',
    margin: '0 auto',
    width: '19rem',
    position: 'relative',
  },
  step: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
    '& >div': {
      gap: '0.2rem',
      display: 'flex',
      flexDirection: 'column',
      '& .subtitle': {
        fontWeight: 300,
        fontFamily: 'Nunito',
        fontSize: '0.75rem',
      },
      '& .title': {
        fontWeight: 500,
        fontFamily: 'Nunito',
        fontSize: '0.75rem',
      },
    },
  },
  textBottom: {
    fontFamily: 'Montserrat',
    color: 'white',
    width: '18.75rem',
    margin: '4rem auto 2rem',
    textAlign: 'center',
  },
  button: {
    margin: '2rem auto',
    width: '17.8rem',
    color: '#282828!important',
    '& >button': {
      width: '17.8rem',
      background: 'rgba(277, 277, 277, 0.8)',
      color: '#282828!important',
      fontWeight: 300,
    },
  },
  line: {
    position: 'absolute',
    width: 1,
    height: '2.5rem',
    borderRight: '1px solid #54e5ff',
    top: '5.2rem',
    left: 10,
  },
}));
